const IMAGES = {
  // LOGO
  //HOME
  banner1: require("../img/home/banner1.webp"),
  banner2: require("../img/home/banner2.webp"),
  banner3: require("../img/home/banner3.webp"),
  banner4: require("../img/home/banner4.jpeg"),
  banner5: require("../img/home/banner5.jpeg"),
  banner6: require("../img/home/banner6.jpeg"),
  banner7: require("../img/home/banner7.jpeg"),
  pgu: require("../img/products/safe.png"),
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
  //
  phonepe: require("../img/products/phonepe.png"),
  gpay: require("../img/products/gpay.png"),
  paytm: require("../img/products/paytm.png"),
  // support
  support: require("../img/contact/support.png"),
  // home slider
  paytm: require("../img/home/paytm.webp"),
  gpay: require("../img/home/gpay.webp"),
  phonepe: require("../img/home/phonepe.webp"),
  upi: require("../img/home/upi.webp"),
};

export default IMAGES;
